// deps
import React from "react"
import { graphql } from "gatsby"

// components
import Layout from "../layouts/layout"
import Form from "../components/form"
import Img from "gatsby-image"
import SEO from "../components/seo"

const config = {
  title: "Contact Form 1",
  successMessage: "Bericht succesvol verzonden",
  errorMessage: "Excuses, probeert u het nog eens",
  fields: {
    firstName: "",
    lastName: "",
    email: "",
    msg: "",
  },
  fieldsConfig: [
    {
      id: 1,
      label: "Voornaam",
      fieldName: "firstName",
      type: "text",
      placeholder: "voornaam",
      isRequired: true,
      className: "first-name-field",
    },
    {
      id: 2,
      label: "Achternaam",
      fieldName: "lastName",
      type: "text",
      placeholder: "achternaam",
      isRequired: true,
      className: "last-name-field",
    },
    {
      id: 3,
      label: "Email",
      fieldName: "email",
      type: "email",
      placeholder: "uw e-mail",
      isRequired: true,
      className: "email-field",
    },
    {
      id: 4,
      label: "Bericht",
      fieldName: "msg",
      type: "textarea",
      placeholder: "inhoud bericht...",
      isRequired: true,
      className: "message-field",
    },
  ],
}

export default function Contact({ location, data }) {

  return (
    <Layout loc={location}>
      <SEO
        title="contact"
        description="Kom met ons in contact voor een BCP op maat"
      ></SEO>
      <div className="subHeader">
        <h1>
          Neem contact met ons op<span className="dot">.</span>
        </h1>
        <p>
          Vul onderstaande gegevens in en één van de partners neemt zo spoedig
          mogelijk contact met u op.
        </p>
      </div>
      <div className="textContainer">
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="Together on the road for continuity and resilience"
          style={{ marginBottom: "2em" }}
        ></Img>
        <Form config={config}></Form>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "algemeen/contactPic.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

/**
 * @config prepareing config prop.
 * api: url for the server endpoint
 * title: form title
 * successMessage: message will show in the UI when mail is successfully sent.
 * errorMessage: message will show in the UI when mail is not sent.
 * fields: this is the name of each field. This should be exact order of the fieldsConfig and fieldsConfig.fieldName should be  the same
 * fieldsConfig = settings for each input/textarea field
 */